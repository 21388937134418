<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="approve-container">
        <div class="title-container common-title">
            <div class="title">审批工单</div>
            <el-button class="common-screen-btn" type="primary" @click="() => { $router.push('/work-order/approve/launch-approve') }">发起审批</el-button>
        </div>
        <div class="common-grey-line-1px"></div>
        <div class="common-screen-container">
            <br/>
            <div class="common-input-container">
                <span>工单名称:</span>
                <el-input class="common-screen-input" v-model="params.taskName" placeholder="工单名称"></el-input>
            </div>
            <div class="common-input-container">
                <span>审批类型:</span>
                <el-select class="common-screen-input" @change="(val) => { commonFun('workTypeId', val) }" v-model="params.workTypeId" placeholder="请选择" filterable clearable>
                <el-option label="普通审批" value="2"></el-option>
                <el-option label="应用场景审批" value="3"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>类型名称:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.workOrderId" placeholder="请选择" filterable clearable>
                    <el-option v-for="(item, index) in taskTypeSelect" :key="index" :label="item.workTypeName" :value="item.id"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>工单进展状态:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.status" placeholder="请选择" filterable >
                    <el-option label="进行中" value="1"></el-option>
                    <el-option label="已完成" value="2"></el-option>
                    <el-option label="终止" value="3"></el-option>
                </el-select>
            </div>
            <br/>
            <div class="common-input-container">
                <span>优先级:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.priority" placeholder="请选择" filterable >
                    <el-option label="低" value="1"></el-option>
                    <el-option label="中" value="2"></el-option>
                    <el-option label="高" value="3"></el-option>
                    <el-option label="紧急" value="4"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>当前处理人:</span>
                <el-input class="common-screen-input" v-model="params.dealName" placeholder="当前处理人"></el-input>
            </div>
             <div class="common-input-container">
                <span>负责人:</span>
                <el-input class="common-screen-input" v-model="params.chargeName" placeholder="负责人"></el-input>
            </div>
            <div class="common-input-container">
                <span>创建人:</span>
                <el-input class="common-screen-input" v-model="params.createName" placeholder="创建人"></el-input>
            </div>
            <br/>
            <div class="common-input-container">
                <span>计划开始时间:</span>
                <el-date-picker style="margin-left: 16px" @change="commonFun" value-format="yyyy-MM-dd HH:mm:ss" v-model="params.planStartTime" type="datetimerange" range-separator="~" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
            </div>
            <div class="common-input-container">
                <span>计划完成时间:</span>
                <el-date-picker style="margin-left: 16px" @change="commonFun" value-format="yyyy-MM-dd HH:mm:ss" v-model="params.planEndTime" type="datetimerange" range-separator="~" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
            </div>
             <div class="common-input-container">
                <el-button class="common-screen-btn" type="primary" @click="() => { taskWorkList() }">查 询</el-button>
                <el-button class="common-screen-btn clear" @click="() => {resetBtn()}" plain>重 置</el-button>
            </div>
        </div>
        
        <div class="common-grey-line"></div>
        <el-tabs v-model="activeName" @tab-click="() => { commonFun() }">
            <el-tab-pane name="1" label="所有工单"></el-tab-pane>
            <el-tab-pane name="2" :label="'我的待办(' + myWait + ')'"></el-tab-pane>
            <el-tab-pane name="3" label="我创建的"></el-tab-pane>
            <!-- <el-tab-pane name="4" label="我负责的"></el-tab-pane> -->
            <el-tab-pane name="5" label="抄送给我的"></el-tab-pane>
            <el-tab-pane name="6" label="已处理审批"></el-tab-pane>
        </el-tabs>
        <div class="common-padding" style="padding-top: 0;">
        <el-table class="common-table" border :data="tableData" header-row-class-name="common-table-bgGrey">
            <el-table-column prop="shopName" label="审批工单名称" width="160px" fixed="left">
                <template slot-scope="scope">
                    <el-button style="white-space:pre-line;line-height: 20px;text-align:left;padding: 0" type="text" size="small" @click="() => { detailShow(scope.row) }">{{scope.row.taskName || '--'}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="workTypeName" label="类型名称" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="status" label="工单进展状态" width="120px" >
                <template slot-scope="scope">
                    <div v-if="scope.row.status == 1" class="common-tableStatus-green">进行中</div>
                    <div v-else-if="scope.row.status == 2" class="common-tableStatus-blue">已完成</div>
                    <div v-else-if="scope.row.status == 3" class="common-tableStatus-grey">已终止</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="审批状态" width="120px" >
                <template slot-scope="scope">
                    <div v-if="scope.row.status == 1" style="color: #52C419">处理中</div>
                    <div v-else-if="scope.row.status == 2" style="color: #1890ff">已通过</div>
                    <div v-else-if="scope.row.status == 3 && scope.row.createId != scope.row.terminationId" style="color: #FF4D4F">已拒绝</div>
                    <div v-else-if="scope.row.status == 3 && scope.row.createId == scope.row.terminationId" style="color: #aaa">已撤销</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column prop="priority" label="优先级" width="100px" >
                <template slot-scope="scope">
                    <div v-if="scope.row.priority == '1'" class="common-tableStatus-grey">低</div>
                    <div v-else-if="scope.row.priority == '2'" class="common-tableStatus-blue">中</div>
                    <div v-else-if="scope.row.priority == '3'" class="common-tableStatus-orange">高</div>
                    <div v-else-if="scope.row.priority == '4'" class="common-tableStatus-red">紧急</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="描述" width="120px" :formatter="tableColumn" show-overflow-tooltip></el-table-column>
            <el-table-column prop="dealName" label="当前处理人" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="planStartTime" label="计划开始时间" width="120px">
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{`${formatDateTimer(scope.row.planStartTime)}`}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="planEndTime" label="计划完成时间" width="120px">
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{`${formatDateTimer(scope.row.planEndTime)}`}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="overdueDuration" label="逾期时长" width="120px" :formatter="tableColumn" >
                <template slot-scope="scope">
                    <div v-if="scope.row.overdueDuration" style="color: #fe4c4e">{{scope.row.overdueDuration}}</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column label="关闭时间" width="120px" >
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{`${formatDateTimer(scope.row.terminationTime)}`}}</div>
                </template>
            </el-table-column>
            <el-table-column label="完成时间" width="120px">
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{formatDateTimer(scope.row.finishTime)}}</div>
                </template>
            </el-table-column>
            <el-table-column label="最后处理时间" width="140px">
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{formatDateTimer(scope.row.latestDealTime)}}</div>
                </template>
            </el-table-column>
            <el-table-column label="剩余处理时间" width="140px">
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{scope.row.surplusDuration || '--'}}</div>
                </template>
            </el-table-column>
            <el-table-column label="完成工单耗时" width="140px">
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{scope.row.completeDuration || '--'}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="createName" label="创建人" width="140px" :formatter="tableColumn" ></el-table-column>
            <el-table-column label="创建时间" width="140px">
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{formatDateTimer(scope.row.createTime)}}</div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="chargeName" label="负责人" width="140px" :formatter="tableColumn" ></el-table-column> -->
        </el-table>
        <el-pagination
            style="text-align: center"
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e)"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            :current-page="params.page"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total"
            >
        </el-pagination>
        </div>
        <approve-detail ref="taskDetail" :drawer="drawer" :handleClose="handleClose"></approve-detail>
    </div>
</template>
<script>
import { tableColumn, formatDateTimer, Config } from "../../utils/index.js"
import { taskWorkList, workOrderType } from "../../service/workOrder.js"
import ApproveDetail from "./children/approveDetail.vue"
import { mapState } from "vuex";
export default {
    components: { ApproveDetail },
    data () {
        return {
            tableColumn,
            formatDateTimer,
            userId: this.$sto.get(Config.constants.userInfo).user_id,
            // companyId: this.$sto.get(Config.constants.userInfo).company_id,
            drawer: false, // 详情
            loading: false,
            activeName: '1',
            taskTypeSelect: [],
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            tableData: [],
            params: {
                page: 1,
                pageSize: 10,
                taskName: '',
                workTypeId: '',
                workOrderId: ''
            },
            myWait: 0,
        }
    },
    computed: {
        ...mapState(["comId"])
    },
    mounted() {
        let workOrderName = this.$route.query.workOrderName
        let workOrderId = this.$route.query.workOrderId
        if (workOrderName && workOrderId) {
            this.params.taskName = workOrderName
            this.detailShow({id: workOrderId})
        }
        this.workOrderType(0)
        this.taskWorkList()
        this.myWaitFun(0)
    },
    methods: {
        async myWaitFun(workTypeId) {
            let params = {
                type: 2,
                adminId:this.userId,
                workTypeId: workTypeId,
                companyId: this.comId
            }
            let { data } = await taskWorkList(params)
            this.myWait = data.total
        },
        detailShow(row) { // 详情弹窗
            this.$refs.taskDetail.getTableParams(row)
            this.drawer = true
        },
        handleClose(isLoad) { // 关闭弹窗  isLoad: true ; 刷新列表
            if (isLoad) { 
                this.taskWorkList()
                this.myWaitFun()
            }
            this.drawer = false
        },
        async workOrderType(workTypeId) { //类型下拉
            let { data } = await workOrderType({workTypeId: workTypeId, pageNum: 1, pageSize: 99999, companyId: this.comId})
            this.taskTypeSelect = data.list
        },
        async taskWorkList() { // 查询列表
            let params = {...this.params}
            params.type = this.activeName
            params.adminId = this.userId
            params.companyId = this.comId
            if (!params.workTypeId) {
                params.workTypeId = 0
            }
            if (params.planStartTime && params.planStartTime.length) {
                params.planStartStartTime = params.planStartTime[0]
                params.planStartEndTime = params.planStartTime[1]
                delete params.planStartTime
            }
            if (params.planEndTime && params.planEndTime.length) {
                params.planEndStartTime = params.planEndTime[0]
                params.planEndEndTime = params.planEndTime[1]
                delete params.planEndTime
            }
            
            this.loading = true
            let { data } = await taskWorkList(params)
            this.loading = false
            this.tableData = data.list
            this.pagination.total = data.total
        },
        commonFun(type, val) {
            this.params.page = 1;
            this.params.pageSize = 10;
            if (type == 'workTypeId') {
                this.params.workOrderId = ''
                this.myWaitFun(val || 0);
                this.workOrderType(val || 0)
            }
            this.taskWorkList()
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e;
            } else {
                this.params.page = e;
            }
            this.taskWorkList()
        },
        resetBtn() { // 重置
            this.params = {
                pageNum: 1,
                pageSize: 10,
                taskName: "",
                workTypeId: '',
                workOrderId: ''
            };
            this.taskWorkList()
        },
    }
}
</script>
<style scoped lang="less">
.approve-container {
  .common-screen-container {
    padding-left: 32px;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .title {
      font-size: 24px;
      color: #101010;
    }
  }
  /deep/ .el-table__header-wrapper {
    background: #f7f7f7;
  }
  /deep/.el-tabs__nav-wrap::after {
    height: 1px;
  }
  /deep/.el-tabs__item {
    padding: 4px 24px 0 !important;
    height: auto;
  }
  .el-tabs__content {
    text-align: left;
  }
}
</style>